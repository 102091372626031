import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import MotionSickness from "./MotionSickness"
import TransitionLink, { TransitionPortal } from 'gatsby-plugin-transition-link'

import FooterMenusWidgets from "./FooterMenusWidgets"
import MenuModal from "./MenuModal"

import 'bootstrap/dist/css/bootstrap.min.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import '../assets/scss/layout.scss'

const backdropClasses = "backdrop"

const Layout = ({ children, bodyClass, transitionStatus, entry, exit }) => {

  const [backdropActive, setBackdropActive] = useState(false)

  const toggleBackdrop = (e, active) => {
    e.preventDefault()
    setBackdropActive(active)
  }


  return (
    <div
    id={"GatsbyBody"}
    className={
      bodyClass +
      " showing-menu-modal showing-modal" +
      (backdropActive ? backdropClasses : "")
    }
  >
    <Helmet>
        <link rel="shortcut icon" type="image/svg" href="/src/images/favicon.svg"/>
        <link rel="preload" href="assets/fonts/Raleway-BlackItalic.woff" as="font" type="font/woff" crossorigin></link>
        <link rel="preload" href="assets/fonts/Raleway-Black.woff" as="font" type="font/woff" crossorigin></link>
        <link rel="preload" href="assets/fonts/Raleway-ExtraBoldItalic.woff" as="font" type="font/woff" crossorigin></link>
        <link rel="preload" href="assets/fonts/Raleway-ExtraBold.woff" as="font" type="font/woff" crossorigin></link>

        <link rel="preload" href="https://fonts.googleapis.com" />
        <link rel="preload" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,800;0,900;1,800;1,900&display=swap" rel="stylesheet"></link>
    </Helmet>
    
    <MotionSickness />
    
    <a className="skip-to-content-link" tabIndex="1" href="#main">
        Skip to Content
    </a>

    <MenuModal isActive={backdropActive} toggleBackdrop={toggleBackdrop} />

    <div className="site-wrap" id="scrollContainer" data-site-wrap>
      <Header toggleBackdrop={toggleBackdrop} />
    
        <div id="scrollSkew" className="scrollskewwrap">
          <div className="scrollskewwrap">
      
            <div data-scroll>
              <div data-scroll-content>
                <div data-scroll-content-wrap>

                  <main id="site-content" role="main">
                    <div id="main" tabIndex="-1"></div>
                    {children}
                  </main>

                </div>

              
                <Footer />
              </div>
            </div>
          </div>
        </div>
     
      </div>
    <div className="cursor-wrap">
      <div className="cursor"></div>
    </div>

      <TransitionPortal level="bottom">
        <div className="layoutBackground"></div>
      </TransitionPortal>
  </div>
  )
}

export default Layout
