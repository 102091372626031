import React from 'react'
//import { Link } from "gatsby"
import TransitionLink from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import Lottie from 'react-lottie'
import travelIcon from '../assets/lottie/map-marker.json'
import catsIcon from '../assets/lottie/angry-cat.json'
import codingIcon from '../assets/lottie/document.json'
import productivityIcon from '../assets/lottie/line-chart.json'
import developmentIcon from '../assets/lottie/line-chart.json'


const PostCategories = ({ categories }) => {
  if (!categories?.nodes || categories.nodes === 0) return null
  // console.log('cat nodes')
  // console.log(categories.nodes)

  var categoryList;


  //Need to add every cat forico
  categoryList = categories.nodes.map(function(category, index, activeClassName, partiallyActive, other) {
    
    if( category.name == "Travel") {

      const travelOptions = {
        loop: false,
        autoplay: true,
        animationData: travelIcon,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        
      }  
    
      return (
        <div key={category.uri} className="entry-categories-inner m-0 mb-3">
       
          <AniLink 
          tabIndex="3"
          fade 
          to={category.uri}
          key={index}
          duration={0.4}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
          rel="category tag"
          id={category.slug}
          className="d-flex align-items-center"
          >
            <Lottie options={travelOptions} height={30} width={30} speed={1}  />
            
            <span className="pl-3">{category.name}</span>
          </AniLink>
        </div>
      )
    } else if ( category.name == "Kitty-Cats") { 
      const catOptions = {
        loop: false,
        autoplay: true,
        animationData: catsIcon,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        
      }  
    
      return (
        <div key={category.uri} className="entry-categories-inner m-0 mb-3">
       
          <AniLink
          tabIndex="3" 
          fade 
          to={category.uri}
          key={index}
          duration={0.4}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
          rel="category tag"
          id={category.slug}
          className="d-flex align-items-center"
          >
            <Lottie options={catOptions} height={30} width={30} speed={1}  />
            
            <span className="pl-3">{category.name}</span>
          </AniLink>
        </div>
      )

    } else if ( category.name == "Coding" ) {
      const codingOptions = {
        loop: false,
        autoplay: true,
        animationData: codingIcon,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        
      }  
    
      return (
        <div key={category.uri} className="entry-categories-inner m-0 mb-3">
       
          <AniLink 
          fade 
          to={category.uri}
          key={index}
          duration={0.4}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
          rel="category tag"
          id={category.slug}
          className="d-flex align-items-center"
          >
            <Lottie options={codingOptions} height={30} width={30} speed={1}  />
            
            <span className="pl-3">{category.name}</span>
          </AniLink>
        </div>
      )
    } else if ( category.name == "Productivity" ) {
      const productivityOptions = {
        loop: false,
        autoplay: true,
        animationData: productivityIcon,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        
      }  
    
      return (
        <div key={category.uri} className="entry-categories-inner m-0 mb-3">
       
          <AniLink 
          fade 
          to={category.uri}
          key={index}
          duration={0.4}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
          rel="category tag"
          id={category.slug}
          className="d-flex align-items-center"
          >
            <Lottie options={productivityOptions} height={30} width={30} speed={1}  />
            
            <span className="pl-3">{category.name}</span>
          </AniLink>
        </div>
      )
    } else if ( category.name == "Development" ) {
      const developmentOptions = {
        loop: false,
        autoplay: true,
        animationData: developmentIcon,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
        
      }  
    
      return (
        <div key={category.uri} className="entry-categories-inner m-0 mb-3">
       
          <AniLink 
          fade 
          to={category.uri}
          key={index}
          duration={0.4}
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
          rel="category tag"
          id={category.slug}
          className="d-flex align-items-center"
          >
            <Lottie options={developmentOptions} height={30} width={30} speed={1}  />
            
            <span className="pl-3">{category.name}</span>
          </AniLink>
        </div>
      )
    }

  }.bind(this));

  return (
    <div className="entry-categories m-0">
      <span className="screen-reader-text">Categories</span>
    
        {categoryList}
        
    </div>
  )
}

export default PostCategories
